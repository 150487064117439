//
// External Dependencies
//


//
// Import styles
//
import './scss/sar.scss';

//
// Local Dependencies
//

// Accept HMR as per:
// https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}